import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { AboutMeta } from "../data/seo-meta"

import manWalkingDog from "../images/about/man_walking_dog.svg"
import sliceOverview from "../images/about/slice_overview.png"
import tim from "../images/about/tim_attia.png"
import stuart from "../images/about/stuart_baserman.png"
import lore from "../images/about/lore_farrell.png"
import keith from "../images/about/keith_janes.png"
import emily from "../images/about/emily_mertz.png"
import linkedin from "../images/about/linkedin-logo.svg"
import heart from "../images/about/heart.svg"
import globe from "../images/about/globe_icon.svg"
import thumb from "../images/about/thumb_icon.svg"
import present from "../images/about/present_icon.svg"
import womanOnComputer from "../images/about/woman_on_computer.svg"

const team = [
  {
    name: "Tim Attia",
    position: "CEO",
    picture: tim,
    link: "https://www.linkedin.com/in/tim-attia-8a322a1/",
  },
  {
    name: "Stuart Baserman",
    position: "CTO",
    picture: stuart,
    link: "https://www.linkedin.com/in/stuart-baserman-1074b1/",
  },
  {
    name: "Lore Farrell",
    position: "VP Product Management",
    picture: lore,
    link: "https://ca.linkedin.com/in/lore-farrell-aa40169/",
  },
  {
    name: "Keith Janes",
    position: "Director Business Programs",
    picture: keith,
    link: "https://www.linkedin.com/in/kmjanes/",
  },
  {
    name: "Emily Mertz",
    position: "VP Marketing",
    picture: emily,
    link: "https://www.linkedin.com/in/emily-mertz-30b8521/",
  },
]

const responsibilities = [
  {
    icon: globe,
    title: "Earth Day, Every Day",
    contents: [
      "We believe we have a responsibility to minimize our carbon footprint in as many ways as we can and that can look different from person to person. Our Slice team pledges to eco-friendly changes not only for Earth Day, but every day.",
    ],
  },
  {
    icon: thumb,
    title: "Fairness & Protection",
    contents: [
      "We design insurance products that are fair, transparent and based on usage and behavior.",
      "Our developers and data science team work diligently at removing bias from our products, maintaining our mission to provide fair, affordable protection for all.",
    ],
  },
  {
    icon: present,
    title: "Season’s Greetings",
    contents: [
      "From our first year as a business, we have committed to supporting the communities in which we operate. Each year, we chose an organization to support through donations and necessities.",
    ],
  },
]

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        description={AboutMeta.description}
        path={AboutMeta.path}
        title={AboutMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2">
          <div>
            <h1 className="h1 mb-8">About Us</h1>
            <p className="p1 mb-12">
              Our mission is to fill protection gaps, whether in the gig economy
              or for small businesses. We’re always looking ahead, giving energy
              to ideas that extend beyond our business and lifetime.
            </p>
          </div>
          <img className="mb-8" src={manWalkingDog} alt="Man walking dog" />
        </div>

        <h2 className="h2 md:max-w-1/2 md:pr-24 lg:-mt-24">
          Innovation that endures.
        </h2>
        <p className="p1 mb-24 md:max-w-1/2 md:pr-24">
          We create products based on fairness and transparency. This sets the
          path for how we build our cloud-based platform, our products, and in
          the partnerships we cultivate.
        </p>

        <div className="mb-12 grid gap-12 md:grid-cols-2 md:mb-24">
          <img
            className="mx-auto"
            src={sliceOverview}
            alt="Slice cloud based platform overview"
          />
          <div>
            <h2 className="h2 mb-8">
              We believe companies like us that have a targeted focus can shape
              the future.
            </h2>
            <p className="p1 mb-8">
              We are a technology company and an insurance company.
            </p>
            <p className="p1 mb-8">
              Our cloud&#8209;based platform is designed to deliver
              on&#8209;demand insurance protection. We are also an experience
              company, changing the way we all experience insurance. By
              harnessing AI, machine learning, and Large Language Models, we see
              and feel real effects, impact, and outcomes.
            </p>
          </div>
        </div>

        <h2 className="h2 mb-8">Quotas and quirks by the numbers.</h2>
        <ul className="grid gap-y-8 mb-48 md:grid-cols-4 md:gap-16">
          <li className="flex flex-col">
            <span className="text-[4.5rem] font-semibold text-[#d91451]">
              250,000<sup>+</sup>
            </span>
            <span className="p2">
              hours committed to code writing excellence.
            </span>
          </li>
          <li className="flex flex-col">
            <span className="text-[4.5rem] font-semibold text-[#d91451]">
              75,000<sup>+</sup>
            </span>
            <span className="p2">cups of coffee fueling imagination.</span>
          </li>
          <li className="flex flex-col">
            <span className="text-[4.5rem] font-semibold text-[#d91451]">
              3,567
            </span>
            <span className="p2">
              Slice stickers on laptops, water bottles, and occasionally on
              bumpers.
            </span>
          </li>
          <li className="flex flex-col">
            <span className="text-[4.5rem] font-semibold text-[#d91451]">
              48
            </span>
            <span className="p2">
              babies added to the Slice family since we started—some human,
              others with tails or scales.
            </span>
          </li>
        </ul>
        <h2 className="h2 mb-8 max-w-[96rem]">
          A magical blend of contagious creativity and technical wizardry.
        </h2>
        <p className="p1 mb-12 md:mb-24">
          Over the years, Slice has developed and launched products such as
          homeshare, cyber, travel, vendor, tenant, item share, and gig worker
          with partners like AXA XL, Lenovo, Microsoft, and Progressive. Meet
          the peeps.
        </p>

        <ul className="grid grid-cols-2 gap-8 max-w-[80rem] mx-auto md:grid-cols-3">
          {team.map((member) => (
            <li className="max-w-[27.6rem]" key={member.name}>
              <div className="relative">
                <img
                  className="w-[100%] rounded-2xl drop-shadow"
                  src={member.picture}
                  alt={member.name}
                />
                <a
                  className="absolute top-[83%] right-4 w-[13%]"
                  href={member.link}
                  rel="noreferrer nofollow"
                  target="_blank"
                >
                  <img src={linkedin} alt="LinkedIn Logo" />
                </a>
              </div>
              <p className="p1 font-semibold pt-4">{member.name}</p>
              <p className="p1">{member.position}</p>
            </li>
          ))}
          <li className="max-w-[27.6rem]">
            <div>
              <img
                className="w-[100%] rounded-2xl drop-shadow"
                src={heart}
                alt="Heart icon"
              />
            </div>
            <p className="p1 pt-4">
              <span className="font-semibold">50+ Slicers</span>{" "}
              <em>and growing</em>
            </p>
          </li>
        </ul>

        <h2 className="h2 pt-36 mb-8">
          Our responsibilities, taken one step at a time.
        </h2>
        <p className="p1 mb-12">
          Today, we’re faced with a number of issues, environmentally and
          socially. Here’s how we take responsibility.
        </p>
        <div className="-mx-8 grid gap-8 md:grid-cols-3 md:mx-0">
          {responsibilities.map((res) => (
            <div
              className="px-12 py-16 rounded-2xl bg-[#f8f8f8] drop-shadow"
              key={res.title}
            >
              <img src={res.icon} alt={res.title} />
              <h3 className="p1 pt-8 mb-2 font-semibold">{res.title}</h3>
              {res.contents.map((content) => (
                <p className="p2 mb-4" key={content}>
                  {content}
                </p>
              ))}
            </div>
          ))}
        </div>

        <div className="md:text-center mt-28 mb-12 md:mt-32">
          <img
            className="mx-auto"
            src={womanOnComputer}
            alt="Woman on a computer"
          />
          <h2 className="h1 my-8 mx-auto max-w-[84rem]">
            Let’s get to know each other better.
          </h2>
          <p className="p1">
            Send us your email to schedule a demo or choose a time to connect.
            <br />
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
